import React from "react";
import { graphql, PageProps } from "gatsby";

import config from "../../clhbid-config";

import SEO from "../components/SEO";

import AuctionContent from "../sections/AuctionContent";

const AuctionTemplate: React.FC<PageProps<Queries.AuctionTemplateQuery>> = ({
  data: { auction },
}) => (
  <>
    <SEO
      title={auction.name}
      description={auction.rawDescription}
      image={auction?.homePictureMetadata?.url}
    >
      <link
        rel="canonical"
        href={`${config.PRODUCTION_SITE_URL}/auctions/${auction.url}`}
      />
    </SEO>
    <AuctionContent auction={auction} />
  </>
);

export const query = graphql`
  query AuctionTemplate($url: String) {
    auction(url: { eq: $url }) {
      ...AuctionContent
    }
  }
`;

export default AuctionTemplate;
